




































































































































import Vue, { VueConstructor } from 'vue';
import HostTemplateTextService from '@/services/owner/HostTemplateTextService';
import { MetaInfo } from 'vue-meta';
import { minLength, required } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import ValidatedInput from '@/components/forms/ValidatedInput.vue';
import ValidatedSelect from '@/components/forms/ValidatedSelect.vue';
import ValidatedTextArea from '@/components/forms/ValidatedTextArea.vue';
import ToastMessage from '@/models/ToastMessage';
import HelpCardMixin from '@/mixins/HelpCardMixin.vue';

const validations = {
  templateText: {
    subjectDe: {
      required,
      minLength: minLength(5),
    },
    textDe: { required },
    // subjectEn: {
    //   required,
    //   minLength: minLength(5),
    // },
    // textEn: { required },
    textType: { required },
    variableType: { required },
  },
};

class TemplateTextEditDto {
  id!: string;
  name!: string;
  subjectDe!: string;
  textDe!: string;
  subjectEn!: string;
  textEn!: string;
  textType!: string;
  variableType!: string;
  templateTextType!: string;
  defaultSubjectDe!: string;
  defaultTextDe!: string;
  defaultSubjectEn!: string;
  defaultTextEn!: string;
}

export default (Vue as VueConstructor<Vue & InstanceType<typeof HelpCardMixin>>).extend({
  components: { ValidatedInput, ValidatedTextArea, ValidatedSelect },
  metaInfo(): MetaInfo {
    return {
      title: this.$t('pages.host.HostTemplateTextEdit.meta.title').toString(),
    };
  },
  props: {
    templateTextId: {
      required: true,
      type: String,
    },
  },
  validations,
  mixins: [validationMixin, HelpCardMixin],
  data() {
    return {
      templateText: new TemplateTextEditDto(),
      loading: false,
      errorMessage: '',
      textTypeOptions: [
        { value: 'PlainText', label: 'Plain Text' },
        { value: 'Markdown', label: 'Markdown' },
        { value: 'Html', label: 'Html' },
      ],
      variableTypeOptions: [
        { value: 'PlainText', label: 'Plain Text' },
        { value: 'Liquid', label: 'Liquid' },
      ],
      focusFor: '',
      previewTextDe: '',
      previewTextEn: '',
      previewSubjectDe: '',
      previewSubjectEn: '',
      showModal: false,
    };
  },
  mounted() {
    this.loadData();
    this.setBreadCrumb();
  },
  methods: {
    handlePreview() {
      HostTemplateTextService.postPreview({ ...this.templateText }).then((res) => {
        this.previewTextDe = res.value.textDe;
        this.previewTextEn = res.value.textEn;
        this.previewSubjectDe = res.value.subjectDe;
        this.previewSubjectEn = res.value.subjectEn;
        this.showModal = true;
      });
    },

    setBreadCrumb() {
      this.$store.commit('UiStoreModule/setBreadcrumbItems', [
        { translationKey: 'pages.host.HostTemplateTextEdit.breadcrumb.last' },
      ]);
    },

    onFocusChange(newFocus: string) {
      if (newFocus == '') {
        this.focusFor = '';
      }
    },

    handleCancel() {
      this.$router.push({ name: 'HostTemplateTextIndex' });
    },

    loadData() {
      this.loading = true;
      HostTemplateTextService.getEdit(this.templateTextId).then((res) => {
        this.loading = false;
        this.templateText = res.value;
        this.setBreadCrumb();
      });
    },

    handleSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.errorMessage = this.$t('forms.common.error_message').toString();
        this.$store.commit(
          'addToastMessage',
          new ToastMessage(this.$t('forms.common.error_message').toString(), 'bg-warning')
        );
      } else {
        this.loading = true;
        return HostTemplateTextService.update({ ...this.templateText }).then(
          () => {
            this.$store.commit(
              'addToastMessage',
              new ToastMessage(this.$t('forms.common.save_successful').toString(), 'bg-success')
            );
            this.$router.push({
              name: 'HostTemplateTextIndex',
            });
          },
          (error) => {
            this.$store.commit(
              'addToastMessage',
              new ToastMessage(this.$t('forms.common.save_error').toString(), 'bg-warning')
            );
            this.loading = false;
            this.errorMessage = error;
          }
        );
      }
    },
  },
});
